import { NavLink } from 'react-router-dom';
import classes from './Header.module.css';

const SearchHeader = () => {
  return (
    <searchheader className={classes.header}>
        <div className={classes.header_search_bar}>
          <svg className={classes.header_searchIcon}>
            <use href="/assets/svgs/stripe.svg#search"></use>
          </svg>
          <input
            className={classes.header_search_input}
            type="text"
            placeholder="Please search here"
          />
          <div className={classes.header_search_fliterIconBox}>
            <svg className={classes.header_search_fliterIcon}>
              <use href="/assets/svgs/stripe.svg#filter"></use>
            </svg>
          </div>
        </div>
    </searchheader>
  );
};
export default SearchHeader;
