import { useState } from 'react';
import classes from './VisaApplication.module.css';
import SideMenu from './SideMenu';

const VisaApplication = () => {
  const [showPhotoUpload, setShowPhotoUpload] = useState(true);
  const [showPassportUpload, setShowPassportUpload] = useState(false);
  const [showFormUpload, setShowFormUpload] = useState(false);
  const [showApplicantPreview, setShowApplicantPreview] = useState(false);

  const photographHandle = () => {
    setShowPhotoUpload(true);
    setShowFormUpload(false);
    setShowPassportUpload(false);
    setShowApplicantPreview(false);
  };
  const passportHandle = () => {
    setShowPhotoUpload(false);
    setShowFormUpload(false);
    setShowPassportUpload(true);
    setShowApplicantPreview(false);
  };
  const formHandle = () => {
    setShowPhotoUpload(false);
    setShowFormUpload(true);
    setShowPassportUpload(false);
    setShowApplicantPreview(false);
  };
  const viewHandle = () => {
    setShowPhotoUpload(false);
    setShowFormUpload(false);
    setShowPassportUpload(false);
    setShowApplicantPreview(true);
  };

  return (
    <SideMenu>
      <div className={classes.va}>
        <h1 className={classes.va_heading}>Application</h1>

        {/* side buttons */}
        <div className={classes.va_nav}>
          <div
            className={
              showPhotoUpload
                ? `${classes.va_nav_btnsActive} ${classes.va_nav_btns}`
                : classes.va_nav_btns
            }
            onClick={photographHandle}
          >
            <svg>
              <use href="/assets/svgs/stripe.svg#photo"></use>
            </svg>
          </div>

          <div
            className={
              showPassportUpload
                ? `${classes.va_nav_btnsActive} ${classes.va_nav_btns}`
                : classes.va_nav_btns
            }
            onClick={passportHandle}
          >
            <svg>
              <use href="/assets/svgs/stripe.svg#passport"></use>
            </svg>
          </div>

          <div
            className={
              showFormUpload
                ? `${classes.va_nav_btnsActive} ${classes.va_nav_btns}`
                : classes.va_nav_btns
            }
            onClick={formHandle}
          >
            <svg>
              <use href="/assets/svgs/stripe.svg#form"></use>
            </svg>
          </div>

          <div
            className={
              showApplicantPreview
                ? `${classes.va_nav_btnsActive} ${classes.va_nav_btns}`
                : classes.va_nav_btns
            }
            onClick={viewHandle}
          >
            <svg>
              <use href="/assets/svgs/stripe.svg#view"></use>
            </svg>
          </div>
        </div>

        {/* photograph */}
        {showPhotoUpload && (
          <div className={classes.va_photo}>
            <h1 className={classes.va_photo_head}>
              Photograph (Passport-size)
            </h1>
            <img
              className={classes.va_photo_img}
              src="/assets/images/avatar.png"
              alt="photograph passport-size"
            />
            <ul className={classes.va_photo_guide}>
              <li>A recent coloured photograph</li>
              <li>Set the colour to true Colour.</li>
              <li>The image file should be .jpg/ .jpeg format.</li>
              <li>Size of file should be between 50 KB-100 KB</li>
            </ul>
            <input className={classes.va_photo_input} type="file"></input>
            <button className={classes.va_photo_upldBtn}>Upload</button>
          </div>
        )}

        {/* passport photo */}
        {showPassportUpload && (
          <div className={classes.va_passport}>
            <h1 className={classes.va_passport_head}>
              Passport scanned images
            </h1>
            <div className={classes.va_passport_select}>
              <img
                className={classes.va_passport_img}
                src="/assets/images/doc_bgc.png"
                alt="photograph passport-size"
              />
              <ul className={classes.va_passport_guide}>
                <li>
                  Set the scanner resolution to a minimum of 200 dpi (dots per
                  inch).
                </li>
                <li>Set the colour to true Colour.</li>
                <li>The image file should be .jpg/ .jpeg format.</li>
                <li>Size of file should be between 100 KB-250 KB</li>
              </ul>

              <input className={classes.va_passport_input} type="file"></input>
            </div>
            <div className={classes.va_passport_select}>
              <img
                className={classes.va_passport_img}
                src="/assets/images/doc_bgc.png"
                alt="photograph passport-size"
              />
              <ul className={classes.va_passport_guide}>
                <li>
                  Set the scanner resolution to a minimum of 200 dpi (dots per
                  inch).
                </li>
                <li>Set the colour to true Colour.</li>
                <li>The image file should be .jpg/ .jpeg format.</li>
                <li>Size of file should be between 100 KB-250 KB</li>
              </ul>

              <input className={classes.va_passport_input} type="file"></input>
            </div>
            <button className={classes.va_passport_upldBtn}>Upload</button>
          </div>
        )}

        {/* applicant form */}
        {showFormUpload && (
          <div className={classes.va_applicant}>
            <h1 className={classes.va_applicant_head}>Applicant details</h1>
            <form method="POST" className={classes.va_applicant_form}>
              <div className={classes.va_applicant_titleDiv}>
                <div className={classes.va_applicant_inputDiv}>
                  <label>Title</label>
                  <select>
                    <option>Mr.</option>
                    <option>Mrs.</option>
                    <option>Ms.</option>
                  </select>
                </div>
                <div className={classes.va_applicant_inputDiv}>
                  <label>First name</label>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="Please enter first name"
                  ></input>
                </div>
              </div>
              <div className={classes.va_applicant_inputDiv}>
                <label>Last name</label>
                <input
                  type="text"
                  name="lastName"
                  placeholder="Please enter last name"
                ></input>
              </div>
              <div className={classes.va_applicant_inputDiv}>
                <label>Date of birth</label>
                <input type="date" name="dob"></input>
              </div>
              <div className={classes.va_applicant_inputDiv}>
                <label>Passport number</label>
                <input
                  type="text"
                  name="passportNumber"
                  placeholder="Please enter passport no."
                ></input>
              </div>
              <div className={classes.va_applicant_inputDiv}>
                <label>Passport issue date</label>
                <input type="date" name="passporIssueDate"></input>
              </div>
              <div className={classes.va_applicant_inputDiv}>
                <label>Passport expiry date</label>
                <input type="date" name="passporExpiryDate"></input>
              </div>
              <div className={classes.va_applicant_inputDiv}>
                <label>Gender</label>
                <select>
                  <option>Male</option>
                  <option>Female</option>
                  <option>Other</option>
                </select>
              </div>
              <div className={classes.va_applicant_inputDiv}>
                <label>Nationality</label>
                <select>
                  <option>Indian</option>
                  <option>Canadian</option>
                  <option>Saudi</option>
                </select>
              </div>
              <div className={classes.va_applicant_inputDiv}>
                <label>Passport country</label>
                <select>
                  <option>India</option>
                  <option>Canada</option>
                  <option>Saudi Arab</option>
                </select>
              </div>
              <div className={classes.va_applicant_inputDiv}>
                <label>Traveler category</label>
                <select>
                  <option>Adult (18+ yrs)</option>
                  <option>Kid (3- 18 yrs)</option>
                  <option>Infant (0 - 2 yrs)</option>
                </select>
              </div>
              <div className={classes.va_applicant_inputDiv}>
                <label>Mobile number</label>
                <input
                  type="text"
                  name="mobileNumber"
                  placeholder="Please enter mobile no."
                ></input>
              </div>
              <div className={classes.va_applicant_inputDiv}>
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Please enter email"
                ></input>
              </div>
              <div className={classes.va_applicant_inputDiv}>
                <label>&nbsp;</label>
                <button className={classes.va_applicant_saveBtn}>Save</button>
              </div>
            </form>
          </div>
        )}

        {/* View all details */}
        {showApplicantPreview && (
          <div className={classes.va_preview}>
            <h1 className={classes.va_preview_h}>Applicant details preview</h1>
            <div className={classes.va_preview_images}>
              <div className={classes.va_preview_imagesDiv}>
                <span
                  className={`material-symbols-outlined ${classes.va_preview_imagesDiv_edit}`}
                >
                  edit
                </span>
                <img
                  src="/assets/images/avatar.png"
                  alt="photograph passport-size"
                />
                <p>Photograph of passport size</p>
              </div>
              <div className={classes.va_preview_imagesDiv}>
                <span
                  className={`material-symbols-outlined ${classes.va_preview_imagesDiv_edit}`}
                >
                  edit
                </span>
                <img
                  src="/assets/images/doc_bgc.png"
                  alt="photograph passport-size"
                />
                <p>Scanned image of front page of passport</p>
              </div>
              <div className={classes.va_preview_imagesDiv}>
                <span
                  className={`material-symbols-outlined ${classes.va_preview_imagesDiv_edit}`}
                >
                  edit
                </span>
                <img
                  src="/assets/images/doc_bgc.png"
                  alt="photograph passport-size"
                />
                <p>Scanned image of back page of passport</p>
              </div>
            </div>
            <div className={classes.va_preview_info}>
              <div className={classes.va_preview_info_div}>
                <p>Name</p>
                <p>Mr. Firstname Lastname</p>
              </div>
              <div className={classes.va_preview_info_div}>
                <p>Date of birth</p>
                <p>01/01/19xx</p>
              </div>
              <div className={classes.va_preview_info_div}>
                <p>Passport number</p>
                <p>ADE458325KJH</p>
              </div>
              <div className={classes.va_preview_info_div}>
                <p>Passport issue date</p>
                <p>02/02/2002</p>
              </div>
              <div className={classes.va_preview_info_div}>
                <p>Passport expiry date</p>
                <p>02/02/2032</p>
              </div>
              <div className={classes.va_preview_info_div}>
                <p>Gender</p>
                <p>Male</p>
              </div>
              <div className={classes.va_preview_info_div}>
                <p>Nationality</p>
                <p>Indian</p>
              </div>
              <div className={classes.va_preview_info_div}>
                <p>Passport country</p>
                <p>India</p>
              </div>
              <div className={classes.va_preview_info_div}>
                <p>Traveler category</p>
                <p>Adult</p>
              </div>
              <div className={classes.va_preview_info_div}>
                <p>Mobile number</p>
                <p>9876543210</p>
              </div>
              <div className={classes.va_preview_info_div}>
                <p>Email</p>
                <p>abc@xyz.com</p>
              </div>
            </div>
            <div className={classes.va_preview_btns}>
              <button className={classes.va_preview_btn}>
                <span className="material-symbols-outlined">thumb_up</span>Final
                submit
              </button>
              <button className={classes.va_preview_btn}>
                <span className="material-symbols-outlined">edit</span>Edit
              </button>
            </div>
          </div>
        )}
      </div>
    </SideMenu>
  );
};
export default VisaApplication;
