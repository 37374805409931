import classes from './HolidayHome.module.css';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { VisaUrls } from '../common/APIConfig';
import SearchHeader from '../header/SearchHeader';

const HolidayHome = () => {
  const [visas, setVisas] = useState([]);
  const headerconfig = {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token')
    },
  };
  const GetTopVisas = () => {
    axios
      .get(VisaUrls.topVisas, { ...headerconfig })
      .then((response) => {
        setVisas(response.data.data);
      });
  };
  useEffect(() => {
    GetTopVisas();
  }, []);
  return (
    <div>
      <SearchHeader></SearchHeader>
      <div className={classes.vh}>
        <ul className={classes.vh_list}>
          {visas.length > 0 && visas.map((item) => (
            <Link to={"/holidays/" + item.visaCountryId}>
              <li key={item} className={classes.vh_list_item}>
                <p className={classes.vh_list_item_tag}>{item.processingType}</p>
                <svg className={classes.vh_list_item_svg}>
                  <use href="/assets/svgs/stripe.svg#heartFull"></use>
                </svg>
                <img
                  className={classes.vh_list_item_img}
                  src={item.imageSrc}
                  alt="popular"
                />
                <div className={classes.vh_list_brief}>
                  <p className={classes.vh_list_title}>{item.name}</p>
                  <p className={classes.vh_list_price}>INR {item.salePrice}/-</p>
                </div>
                <ul className={classes.vh_list_item_info}>
                  <li className={classes.vh_list_item_info_li}>
                    <p>Processing time</p>
                    <p>{item.processingTime} Days</p>
                  </li>
                  <li className={classes.vh_list_item_info_li}>
                    <p>Stay period</p>
                    <p>{item.stayPeriod}</p>
                  </li>
                  <li className={classes.vh_list_item_info_li}>
                    <p>Validity</p>
                    <p>{item.validity}</p>
                  </li>
                  <li className={classes.vh_list_item_info_li}>
                    <p>Entry</p>
                    <p>{item.entry}</p>
                  </li>
                </ul>
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default HolidayHome;

