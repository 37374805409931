import classes from './SideMenu.module.css';
const SideMenu = ({ children }) => {
  return (
    <div className={classes.side}>
      <div className={classes.side_menu}>
        <div className={classes.side_menuItem}>
          <svg>
            <use href="/assets/svgs/stripe.svg#person"></use>
          </svg>
        </div>
        <div className={classes.side_menuItem}>
          <svg>
            <use href="/assets/svgs/stripe.svg#person"></use>
          </svg>
        </div>
        <div className={classes.side_menuItem}>
          <svg>
            <use href="/assets/svgs/stripe.svg#person"></use>
          </svg>
        </div>
        <div className={classes.side_menuItem}>
          <svg>
            <use href="/assets/svgs/stripe.svg#person"></use>
          </svg>
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};
export default SideMenu;
