import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import VisaList from './components/visa/VisaList';
import VisaHome from './components/visa/VisaHome';
import HeaderLayout from './components/ui/HeaderLayout';
import HolidayHome from './components/holiday/HolidayHome';
import VisaApplication from './components/dashbord/VisaApplication';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HeaderLayout />,
    children: [
      {
        index: true,
        element: <VisaHome />,
      },
      {
        path: 'holidays',
        element: <HolidayHome />,
      },
      {
        path: 'visas',
        element: <VisaHome />,
      },
      {
        path: 'visas/:visaCountryId',
        element: <VisaList />,
      },
      {
        path: 'visa/:visaId',
        element: <VisaApplication />,
      }
    ],
  },
  {
    path: 'visas',
    element: <HeaderLayout/>,
    children: [
      {
        path: ':visaCountryId',
        element: <VisaList />,
      },
      {
        path: ':visaId',
        element: <VisaApplication />,
      }
    ],
  },
  {
    path: 'holidays',
    element: <HeaderLayout homePage={false} />,
    children: [
      {
        path: ':visaCountryId',
        element: <VisaList />,
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};
export default App;
