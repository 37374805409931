const ServerAPI = 'https://trawtelvisa20240106091734.azurewebsites.net';
const LocalAPI = 'https://localhost:7231';
const ActiveAPI = ServerAPI;

export const VisaUrls = {
  topVisas: `${ActiveAPI}/api/visa/gettop`,
  searchVisa: `${ActiveAPI}/api/visa/getall`,
  getVisaByCountryName: `${ActiveAPI}/api/visa/getbycountryname`,
  getVisaByCountryId: `${ActiveAPI}/api/visa/getbycountryid`,
  getVisaById: `${ActiveAPI}/api/visa/`
};
