import classes from './HeaderLayout.module.css';
import Header from '../header/Header';
import { Outlet } from 'react-router-dom';

const HeaderLayout = () => {
  return (
    <div className={classes.layout}>
      <Header/>
      <Outlet />
    </div>
  );
};
export default HeaderLayout;
