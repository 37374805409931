import { useEffect, useState } from 'react';
import classes from './VisaList.module.css';
import axios from 'axios';
import { VisaUrls } from '../common/APIConfig';
import { Link, useParams } from 'react-router-dom';

const VisaList = () => {
  const params = useParams();
  const [visaCountry, setVisaCountry] = useState([]);
  const [visas, setVisas] = useState([]);
  const headerconfig = {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token')
    },
  };
  const GetTopVisas = () => {
    axios
      .get(VisaUrls.getVisaByCountryId + "?id=" + params.visaCountryId, { ...headerconfig })
      .then((response) => {
        setVisaCountry(response.data.data);
        setVisas(response.data.data.visas);
      });
  };
  useEffect(() => {
    GetTopVisas();
  }, []);
  return (
    <div className={classes.vl}>
      <div className={classes.vl_header}>
        <h1 className={classes.vl_header_title}>{visaCountry.name} Visa</h1>
        <div className={classes.vl_header_bar}>
          <div className={classes.vl_header_bar_info}>
            <svg className={classes.vl_header_bar_svg}>
              <use href="/assets/svgs/stripe.svg#timer"></use>
            </svg>
            <p className={classes.vl_header_bar_info_h}>Processing time</p>
            <p className={classes.vl_header_bar_info_b}>{visaCountry.lowestProcessingTime === 1 ? "24 Hours" : visaCountry.lowestProcessingTime + " Days"}</p>
          </div>
          <div
            className={`${classes.vl_header_bar_info} ${classes.vl_header_bar_info2}`}
          >
            <svg className={classes.vl_header_bar_svg}>
              <use href="/assets/svgs/stripe.svg#money"></use>
            </svg>
            <p className={classes.vl_header_bar_info_h}>Starting from</p>
            <p className={classes.vl_header_bar_info_b}>&#8377; {visaCountry.lowestPrice}/-</p>
          </div>
          <img
            className={classes.vl_header_bar_img}
            src="/assets/images/dubai.jpg"
            alt="Location"
          ></img>
        </div>
      </div>
      <div className={classes.vl_list}>
        {visas.length > 0 && visas.map((item) => (
          <div className={classes.vl_list_item} key={item}>
            <h2 className={classes.vl_list_item_title}>{item.visaName}</h2>
            <ul className={classes.vl_list_item_ul}>
              <li className={classes.vl_list_item_li}>
                <p>Processing time</p>
                <p>{item.processingTime === 1 ? "24 Hours" : item.processingTime + " Days"}</p>
              </li>
              <li className={classes.vl_list_item_li}>
                <p>Stay period</p>
                <p>{item.stayPeriod}</p>
              </li>
              <li className={classes.vl_list_item_li}>
                <p>Validity</p>
                <p>{item.validity}</p>
              </li>
              <li className={classes.vl_list_item_li}>
                <p>Entry</p>
                <p>{item.entry}</p>
              </li>
              <li className={classes.vl_list_item_li}>
                <p>Price</p>
                <p>INR {item.price}/-</p>
              </li>
            </ul>
            <Link to={"/visa/"+item.visaId} className={classes.vl_list_item_btn}>Apply</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VisaList;
