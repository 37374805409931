import { NavLink } from 'react-router-dom';
import classes from './Header.module.css';

const Header = () => {
  return (
    <header className={classes.header}>
      <div
        className={classes.header_logo}
        onClick={() => window.location.replace('/')}
      >
        myfirstbooking
      </div>
      <div className={classes.header_tabs}>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? classes.header_tabsActive : ''
            }
          >
            Visa
          </NavLink>
          <NavLink
            to="/holidays"
            className={({ isActive }) =>
              isActive ? classes.header_tabsActive : ''
            }
          >
            Holiday
          </NavLink>
        </div>
        <div className={classes.header_acc}>
        <svg className={classes.header_acc_fav}>
          <use href="/assets/svgs/stripe.svg#heartFull"></use>
        </svg>
        <div className={classes.header_acc_acc}>
          <svg className={classes.header_acc_acc_svg}>
            <use href="/assets/svgs/stripe.svg#account"></use>
          </svg>
          <span className={classes.header_acc_acc_link}>My Account</span>
        </div>
      </div>
    </header>
  );
};
export default Header;
